import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import InformationLayout from "../../layouts/InformationLayout.jsx";
import indexData from "./index.json";
export const _frontmatter = {
  "title": "Arbour Assembly - Staking Out & Positioning of the Posts"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  pagesList
}) => <InformationLayout pagesList={indexData.pageIndex}>
    {children}
  </InformationLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Step 1. Staking Out & Positioning of the Posts`}</h1>
    <p>{`Once you have prepared and measured the site it is a good idea to stake out the area and mark clearly where your posts will be situated before you start assembly. Using a tape measure or a piece of string, clearly layout the full extent of the structure's footprint with either temporary markers or stakes. Lines A and B should be equal in length. Ensure that the footprint is completely straight by using a builders square to check that all corners are 90 degrees.`}</p>
    <p>{`Depending upon the length of the pergola or arbour you will see from the drawings below how the 'footprint' of the structure will look.`}</p>
    <h4>{`Module 4 - 9ft x 9ft Stand Alone Pergola`}</h4>
    <div className="captioned">
  <img src="/img/arbour-assembly/step-1-staking-out-positioning-of-the-posts/mod4-marker.png" alt="A birds-eye schematic of Module 4 displaying it's 2.16m squared footprint" />
  <span className="text-metadata">Fig 7</span>
    </div>
    <h4>{`Module 5 - 9ft x 16ft Stand Alone Pergola`}</h4>
    <div className="captioned">
  <img src="/img/arbour-assembly/step-1-staking-out-positioning-of-the-posts/mod5-marker.png" alt="A birds-eye schematic of Module 5. It displays a footprint equal to two 2.16m areas squared flush against each other, forming a total footprint of 2.16 x 4.32m" />
  <span className="text-metadata">Fig 8</span>
    </div>
    <h4>{`Module 6 - 9ft x 23ft Stand Alone Pergola`}</h4>
    <div className="captioned">
  <img src="/img/arbour-assembly/step-1-staking-out-positioning-of-the-posts/mod6-marker.png" alt="A birds-eye schematic of Module 6. It displays a footprint equal to three 2.16m areas squared flush against each other, forming a total footprint of 2.16 x 6.48m" />
  <span className="text-metadata">Fig 9</span>
    </div>
    <h4>{`Module 7 - 1.5mtrs x 1.5mtrs (Small)`}</h4>
    <div className="captioned">
  <img src="/img/arbour-assembly/step-1-staking-out-positioning-of-the-posts/mod7-marker.png" alt="A birds-eye schematic of Module 7 displaying it's 1.5m squared footprint" />
  <span className="text-metadata">Fig 10</span>
    </div>
    <h4>{`Module 8 - 1.5mtrs x 2.5mtrs (Medium)`}</h4>
    <div className="captioned">
  <img src="/img/arbour-assembly/step-1-staking-out-positioning-of-the-posts/mod8-marker.png" alt="A birds-eye schematic of Module 7 displaying it's 1.5m x 2.5m footprint" />
  <span className="text-metadata">Fig 11</span>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      